<template>
  <div>
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-project-create"
    />
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :searchKeys="searchKeys"
        :data="items"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <AddNewContent name="landing-page-create" />

          <!-- ITEMS PER PAGE -->
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
        </div>

        <template slot="thead">
          <vs-th sort-key="title">ชื่อ</vs-th>
          <vs-th>URL</vs-th>
          <!-- <vs-th sort-key="published_date">วันที่เผยแพร่</vs-th> -->
          <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.title }}</td>
              <!-- <vs-td>{{ '/'.concat(tr.lang, '/landing-page/', tr.slug) }}</vs-td> -->
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ '/'.concat(tr.lang, '/landing-page/', tr.slug) }}
                <a
                  @click.stop=""
                  :href="websiteUrl.concat('/', tr.lang, '/landing-page/', tr.slug)" 
                  target="_blank"
                ><link-icon size="1.0x" /></a></td>
              <!-- <vs-td>{{ publicDateFormat(tr.published_date) }}</vs-td> -->
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority">{{ updatedAtDatetimeFormat(tr.updated_at) }}</p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <feather-icon
                    class="cursor-pointer"
                    icon="CopyIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="duplicateContent(tr.id)"
                  />
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                    class="pt-2 color-text-link"
                  >
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                  <feather-icon
                    class="cursor-pointer"
                    icon="TrashIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="deleteData(tr.id)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
// import get from 'lodash/get'
import { computed } from '@vue/composition-api'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import AddNewContent from '@/components/AddNewContent'
import PopupLanguage from '@/components/PopupLanguage'
import useLandingPageUtil from '@/use/useLandingPageUtil'
import omit from 'lodash/omit'
import get from 'lodash/get'
import { LinkIcon } from 'vue-feather-icons'
import env from '@/env'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import { getUniqueValue } from '@/helper/Utils'
import CustomTable from '@/components/CustomTable'

// import { createFolder } from '@/helper/damUtils'
// import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'LandingPageListView',
  components: { 
    AddNewContent,
    LinkIcon,
    PopupLanguage,
    LhDropdownPerPage,
    CustomTable
  },
  data() {
    return {
      websiteUrl: `${env.VUE_APP_WEBSITE_URL}`.replace(/\/$/, "")
    }
  },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'landingPage')
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    const { formData, prepareFormData } = useLandingPageUtil(ctx)

    crudFunction.routePrefix.value = 'landing-page'

    const items = computed(() => {
      return crudFunction.result.value
    })

    const handlerDuplicateContentFromCRUD = async (
      formData,
      prepareFormData,
      langToDuplicationContent,
    ) => {
      ctx.root.$vs.loading()
      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
      const result = await ctx.root.$store.dispatch(
        'landingPage/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await ctx.root.$store.dispatch('landingPage/fetch')
      await prepareFormData(result)

      const items = crudFunction.result.value

      const title = formData.title.concat('_copy')
      formData.title = getUniqueValue(items, title, 'title')
      if (formData.slug) {
        const slug = formData.slug.concat('_copy')
        formData.slug = getUniqueValue(items, slug, 'slug')
      }
      if (formData.custom_slug) {
        const custom_slug = formData.custom_slug.concat('_copy')
        formData.custom_slug = getUniqueValue(items, custom_slug, 'custom_slug')
      }
      

      const activeUser = ctx.root.$store.state.AppActiveUser
      const email = get(activeUser, 'email', '')
      formData.created_by = email
      formData.updated_by = email


      // create new folder
      // const folders = ['Image', 'Seo Thumbnail']
      // const randomUuid = uuidv4()
      // formData.folder_name = randomUuid
      // await createFolder(`content/${randomUuid}`, folders)

      const resultDuplicate = await ctx.root.$store.dispatch('landingPage/addItem', omit(formData, ['id', 'revision_id', 'updated_at']))
      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      await ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: {
          id: resultDuplicate.id,
        },
      })
    }

    const handlerDuplicateContent = (langToDuplicationContent) => {
      handlerDuplicateContentFromCRUD(formData, prepareFormData, langToDuplicationContent)
    }

    const publicDateFormat = (value) => {
      return moment(value).format('DD MMM YYYY')
    }

    // const queriedItems = computed(() => {
    //   return ctx.refs.table ? ctx.refs.table.queriedResults.length : items.value.length
    // })

    return {
      ...crudFunction,
      items,
      handlerDuplicateContent,
      publicDateFormat,
      updatedAtDatetimeFormat,
      searchKeys: ['title', 'slug']
    }
  },
}
</script>

<style scoped></style>
